<template>
  <div class="common-module change-module-two relative">
    <div class="module-content table-wrapper">
      <c-table
        :data="pageData"
        :tableColumns="tableColumns"
        v-loading="pageLoading"
        :stripe="true"
      >
        <template v-slot:status="{ row }">
          <div class="text-center">
            <span
              class="alert-status"
              :class="{
                green: row.status == 1,
                red: row.status == 2,
                orange: row.status == 3,
              }"
            ></span>
          </div>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
import Configs from "../config/moduleTwo";
export default {
  props:{
    pageData:Array,
    pageLoading:Boolean,
    sensorData: Array,
    type: Number
  },
  watch: {
    type: {
      handler() {
        // console.log("typeChange", val);
      },
      deep: true,
      immediate: true,
    },
    pageData: {
      handler() {
        // console.log("typeChangePageData", val, this.sensorData);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    let { tableColumns } = Configs(this);
    return {
      tableColumns,
    };
  },
	methods: {
		getMqData(body){
      for(let i = 0; i < this.pageData.length; i++){
        let data = this.sensorData[i];
        let sign = data.isOpposite === 1 ? 1 : -1;
        if (data.calValue) {
          this.pageData[i].sensorData = ((data.calValue - body[this.pageData[i].columnName]) * sign).toFixed(data.decimal);
        } else {
          this.pageData[i].sensorData = (body[this.pageData[i].columnName]).toFixed(data.decimal);
        }
      }
    }
	},
};
</script>
<style scoped>
  .common-module .module-content{
    height: 100%;
  }
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 4px; /*滚动条宽度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #828282 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #828282; /*滚动条的背景颜色*/
  }
</style>
