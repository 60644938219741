<template>
  <div ref="dom" class="charts chart-line"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object
    },
    data() {
      return {
        dom: null,
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {},
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        this.$nextTick(() => {
          let option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            grid:{
              top:70,
              left:70,
              bottom:50,
              right:30
            },
            legend: {
              show: false
            },
            xAxis: {
              name:val.xAxis.name,
              type: 'category',
              data: val.xAxis.data,
              nameLocation:'center',
              nameGap:25,
            },
            yAxis: {
              name:"",
              type: 'value',
              nameLocation:'center',
              nameGap:50,
            },
            series: [
              {
                name: "频数",
                type: "bar",
                data:val.data,
                barWidth:8
              }
            ],
          };
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          this.$parent.changeCommonLoad3()
        });
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
<style scoped>
  .charts{
    width: 100%;
    height: calc(100% - 30px);
  }
</style>
