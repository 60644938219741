<template>
  <div class="common-module change-module-one relative">
    <div class="left-wrapper">
      <ul v-if="typeList && typeList.length > 0">
        <li
          v-for="(item, index) in typeList"
          :key="index"
          :class="{ active: item.id == currentType }"
          @click="monitorChange(item)"
          :title="item.name"
        >
          <img
            :src="
              require('../../../../../../assets/images/admin/single/icon/' +
                (item.id ==
                currentType
                ? item.activeIcon
                : item.icon) + '.png')
            "
            alt=""
          />
          <span class="ml-1 ellipsis">{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="bridge-wrapper">
      <div id="bridge1" v-loading="loading"></div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="添加"
        :visible.sync="dialogPoint"
        :destroy-on-close="true"
        width="660px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="pointForm" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="24">
              <el-form-item label="点类型" prop="pointType">
                <el-select size="small" v-model="pointForm.pointType" placeholder="请选择点类型">
                  <el-option label="点" :value="1"></el-option>
                  <el-option label="截面" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="监测点" prop="sensorDetailId">
                <el-select v-model="pointForm.sensorDetailId" placeholder="请选择" clearable @change="test">
                  <el-option v-for="(item, index) in sensorList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="点位名称" prop="sectionName">
                <el-input v-model="pointForm.sectionName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    text: String
  },
  data() {
    return {
      currentType: 1,
      typeList: [],
      svg:{
        zoom_:null,
        svg_:null,
        svg1:null,
        container:null,
        container1:null,
        svg_width:1200,
        svg_height:700,
        isScale:0
      },
			loading: false,
			imgWidth: 1200*1.2,
			imgHeight: 700*1.2,
      delay:200,

      dialogPoint: false,
      sensorList: [],
      pointForm: {
        pointType: 1,
        sensorDetailId: null,
        sectionName: null
      },
      monitorId: null
    };
  },
	created() {
    this.init();
	},
  mounted() {
  },
  methods:{
    // test
    test(e) {
      console.log(e)
      for (let i = 0; i < this.sensorList.length; i++) {
        if (this.sensorList[i].id == e) {
          this.pointForm.sectionName = this.sensorList[i].name
          return
        }
      }

    },
    //初始图
    initSvg(monitorId){
			this.loading = true;
			this.monitorId = monitorId;
			this.$http.get("/point/chartList",{
				params: {
					monitorId: monitorId
				}
			}).then(res=>{
				this.loading = false;
				if(res.code == 200 && res.data && res.data.chart){
          this.pointForm.chartId = res.data.chart.id
					this.svg.svg_width = document.getElementById('bridge1').offsetWidth
					this.svg.svg_height = document.getElementById('bridge1').offsetHeight
					this.svg.zoom_ = this.$d3.zoom().scaleExtent([0.5, 3]).on("zoom", this.zoomed);
					this.svg.svg_ = this.$d3
					    .select("#bridge1")
					    .append("svg")
					    .attr("class",'svg')
					    .attr("width", this.svg.svg_width)
					    .attr("height", this.svg.svg_height)
					    .call(this.svg.zoom_);
					this.svg.container = this.svg.svg_.append("g").attr("fill","#ffffff").on('click',this.clickContainer);
					this.svg.container
					    .attr("class", "all")
					    .append("image")
					    .attr("class", "image")
					    .attr("xlink:href", res.data.chart.imgUrl)
					    .attr("x", this.imageX())
					    .attr("y", this.imageY())
					    .attr("width",this.imgWidth)
					    .attr("height",this.imgHeight)
							.attr("chartId",res.data.chart.id)
					    .attr("depth","2");
          // console.log(res.data.point);
          if(res.data.point && res.data.point.length > 0){
						for(let i = 0; i < res.data.point.length; i++){
							this.initPoint(Number(res.data.point[i].lng)/Number(res.data.point[i].width) * this.imgWidth + this.imageX(),
														 Number(res.data.point[i].lat)/Number(res.data.point[i].height) * this.imgHeight + this.imageY(),
														 res.data.point[i]);
						}
					}
				}
			})
    },
		clickContainer(e){
			let str = this.svg.container.attr("transform");
			let x = e.offsetX;
			let y = e.offsetY;
			if(str){
				let _str = str.match(/\((.+?)\)/g)
				let translate = _str[0].replace("(","").replace(")","").split(",");
				let scale = _str[1].replace("(","").replace(")","");
				x = (x - Number(translate[0]))/scale;
				y = (y - Number(translate[1]))/scale;
			}
      // console.log(this.svg.svg_width,this.svg.svg_height)
      // TODO 录入点位信息
      let lng = x - this.imageX(); let lat = y - this.imageY();
      let width = this.imgWidth; let height = this.imgHeight;
      this.pointForm.lng = lng; this.pointForm.lat = lat;
      this.pointForm.width = width; this.pointForm.height = height;
      console.log(lng, lat, width, height);
      if(localStorage.getItem("userInfo").includes("monitor")) this.dialogPoint = true;
    },
		initPoint(x,y,point){
			if(point.pointType == "1"){
				this.initCircle(x,y,point);
			}else if(point.pointType == "2"){
				this.initRect(x,y,point);
			} else if (point.pointType == "3"){
        this.initSquare(x,y,point);
      }
		},
    //添加点
    initCircle(x,y,point){
      let container_dots = this.svg.container.append("g").attr("class", "dots");
      let container_ci = container_dots.append("g").attr("class", "point");
      let self = this
      container_ci.append("circle").attr("cx", x)
          .attr("cy", y)
          .attr("r",3*1.2)
          .attr("fill","#307bbe")
					.attr("pointId",point.id)
          .attr("id","point1")
					.on("click",function () {
            self.clickPoint(point)
          })
					.on("mouseover",function(){
						self.mouseoverRect(point);
					})
					.on("mouseout",function(){
						self.svg.container.select("text").remove();
					});
    },
		initRect(x,y,point){
			const self = this;
			this.svg.container.append("rect")
				.attr("x",x - 1.5*1.2)
				.attr("y",y - 4*1.2)
				.attr("width",3*1.2)
				.attr("height",8*1.2)
				.attr("pointId",point.id)
				.style("fill","#307bbe")
				.on("click",function(){
					 self.clickRect(point.id);
				})
				.on("mouseover",function(){
					self.mouseoverRect(point);
				})
				.on("mouseout",function(){
					self.svg.container.select("text").remove();
				});
		},
    initSquare(x,y,point){
      const self = this;
      this.svg.container.append("rect")
          .attr("x",x - 3*1.2)
          .attr("y",y - 3*1.2)
          .attr("width",6*1.2)
          .attr("height",6*1.2)
          .attr("pointId",point.id)
          .style("fill","#307bbe")
          .on("click",function(){
            self.$router.push('/single/special/connection')
          })
          .on("mouseover",function(){
            self.mouseoverRect(point);
          })
          .on("mouseout",function(){
            self.svg.container.select("text").remove();
          });
    },
    //放大缩小
    zoomed({transform}){
      let g = this.$d3.selectAll('.all')
      g.attr("transform", transform);
    },
    //点击点
    clickPoint(data){
      this.$parent.clickPoint(data)
      //	this.initSvg1();
    },
		mouseoverPoint(point){
			this.svg.container.append("text")
				.attr("x", Number(point.lng)/Number(point.width) * this.imgWidth + this.imageX() - 5 )
				.attr("y", Number(point.lat)/Number(point.height) * this.imgHeight + this.imageY() - 18)
				.attr("fill","red")
				.text(point.name);
		},
		mouseoverRect(point){
			this.svg.container.append("text")
				.attr("x", Number(point.lng)/Number(point.width) * this.imgWidth + this.imageX() - 5 )
				.attr("y", Number(point.lat)/Number(point.height) * this.imgHeight + this.imageY() - 18)
				.attr("fill","red")
				.text(point.sectionName);
		},
		clickRect(pointId){
      let time = this.delay;
			this.loading = true;
			this.$http.get("/point/linkData",{
				params:{
					pointId: pointId
				}
			}).then(res=>{
				this.loading = false;
				if(res.code == 200 && res.data && res.data.chart){
          this.pointForm.chartId = res.data.chart.id
					//移除点
					this.svg.container.select("circle").remove();
					this.svg.container.select("rect").remove();
					this.svg.container.select("text").remove();
					//先改变image的大小及定位，实现动画效果；再改名外层svg的大小和定位；最后基于改变后的svg位置，重新给image定位，防止图片丢失
					this.svg.container.attr("transform","translate(0,0) scale(1)")
					this.svg.container.select("#bridge1 .image").transition().duration(time).attr("width",'200').attr("height",'150').attr("x", this.svg.svg_width - 200).attr("y", "0")
					this.svg.svg_.transition().delay(time).attr("width",'200').attr("height",'150').style("position", "absolute").style("right", "0").style("z-index","9")
					this.svg.container.select("#bridge1 .image").transition().delay(time).attr("x", "0").attr("y", "0");
					if(this.svg.svg1){
						this.svg.svg1.remove();
					}
					this.svg.svg1 = this.svg.svg_;
					this.svg.container1 = this.svg.container;
					this.svg.container1.classed("all",false);
					this.svg.container1.select(".image").on("click",this.clickImage);
          // 在图片实现动态效果的过程中，添加一个div作为遮盖层，动态效果完成后移除，避免在此期间用户点击图片出现定位错误的问题
          let div = this.$d3
              .select("#bridge1")
              .append("div")
              .attr("class",'svg-bj')
              .style("width", this.svg.svg_width + "px")
              .style("height", this.svg.svg_height + "px")
              .style("position", "absolute")
              .style("left", "0")
              .style("z-index","11");
          let self = this
          setTimeout(function () {
            self.$d3.select(".svg-bj").remove()
          },self.delay * 2)
					this.svg.svg_ = this.$d3
					    .select("#bridge1")
					    .append("svg")
					    .attr("class",'svg')
					    .attr("width", this.svg.svg_width)
					    .attr("height", this.svg.svg_height)
					    .call(this.svg.zoom_);
					this.svg.container = this.svg.svg_.append("g").attr("fill","#ffffff").on('click',this.clickContainer)
					this.svg.container
					    .attr("class", "all")
					    .append("image")
					    .attr("class", "image")
					    .attr("xlink:href", res.data.chart.imgUrl)
					    .attr("x", -this.svg.svg_width)
					    .attr("y", "0")
							.attr("chartId",res.data.chart.id)
					    .attr("width",this.imgWidth)
					    .attr("height",this.imgHeight);
					this.svg.container.select(".image").on("click",null);
					this.svg.container.select(".image").transition().delay(time).duration(time).attr("x", this.imageX()).attr("y", this.imageY());
					if(res.data.point && res.data.point.length > 0){
						for(let i = 0; i < res.data.point.length; i++){
							this.initPoint(( this.imgWidth/Number(res.data.point[i].width)) * Number(res.data.point[i].lng) + this.imageX(),
														 ( this.imgHeight/Number(res.data.point[i].height)) * Number(res.data.point[i].lat) + this.imageY() ,
														 res.data.point[i]);
						}
					}
				}
			})
		},

    //点击缩小后的桥
    clickImage(){
			this.loading = true;
      let self = this
      let time = this.delay;
      let div = this.$d3
          .select("#bridge1")
          .append("div")
          .attr("class",'svg-bj')
          .style("width", this.svg.svg_width + "px")
          .style("height", this.svg.svg_height + "px")
          .style("position", "absolute")
          .style("left", "0")
          .style("z-index","11");
      setTimeout(function () {
        self.$d3.select(".svg-bj").remove()
      },self.delay * 2)
      this.svg.container.attr("transform","translate(0,0) scale(1)")
      //this.$d3.selectAll('.svg1 g').attr("transform","translate(0,0) scale(1)")
      //先将大图平移出画面，实现动画效果，再移除整个节点
      this.svg.container.select("circle").remove();
			this.svg.container.select("rect").remove();
			this.svg.container.select("text").remove();
      this.svg.container.select("#bridge1 .image").transition().duration(time).attr("x", -self.svg.svg_width).attr("y", "0")
      this.svg.svg_.transition().delay(time).remove();
      this.svg.svg_ = this.svg.svg1;
      this.svg.container = this.svg.container1;
      this.svg.container1 = null
      this.svg.svg1 = null
      //先将svg大小变回原来的尺寸，再改变image的大小，实现动画效果；并将all的类名改回来，实现放大缩小和移动功能；再添加点位
      this.svg.container.classed("all", true);
			this.svg.container.on('click',this.clickContainer);
      this.svg.svg_.attr("width", self.svg.svg_width).attr("height", self.svg.svg_height)
      this.svg.container.select("#bridge1 .image").attr("x", self.svg.svg_width - 200).attr("y", "0")
      this.svg.container.select("#bridge1 .image").transition().delay(time).duration(time).attr("width",self.imgWidth).attr("height",self.imgHeight).attr("x", this.imageX()).attr("y",  this.imageY())
			this.svg.container.select("#bridge1 .image").on("click",null);
			let chartId = this.svg.container.select(".image").attr("chartId");
      this.$http.get("/point/getParentData",{
      	params:{
      		chartId: chartId
      	}
      }).then(res=>{
      	this.loading = false;
      	if(res.code == 200 && res.data){
					if(res.data.point && res.data.point.length > 0){
						for(let i = 0; i < res.data.point.length; i++){
							this.initPoint(Number(res.data.point[i].lng)/Number(res.data.point[i].width) * this.imgWidth + this.imageX(),
														 Number(res.data.point[i].lat)/Number(res.data.point[i].height) * this.imgHeight + this.imageY(),
														 res.data.point[i]);
						}
					}
					if(res.data.parentChart){
            this.pointForm.chartId = res.data.parentChart.id
						this.svg.svg1 = this.$d3
						    .select("#bridge1")
						    .append("svg")
						    .attr("class",'svg')
						    .attr("width", '200')
						    .attr("height", '150')
								.style("position", "absolute")
								.style("right", "0")
								.style("z-index","9")
						    .call(this.svg.zoom_);
						this.svg.container1 = this.svg.svg1.append("g").attr("fill","#ffffff")
						this.svg.container1
						    .append("image")
						    .attr("class", "image")
						    .attr("xlink:href", res.data.parentChart.imgUrl)
						    .attr("x",self.svg.svg_width - 200)
						    .attr("y", "0")
								.attr("chartId", res.data.parentChart.id)
						    .attr("width",'200')
						    .attr("height",'150');
						this.svg.container1.select("#bridge1 .image").on("click",this.clickImage);
						this.svg.container1.select("#bridge1 .image").transition().delay(time).duration(time).attr("x",'0')
					}
      	}
      });
    },
		//初始化页面
		init(){
			this.$http.get("/monitorDict/getChildrenByType",{
				params:{
					type: this.text
				}
			}).then(res=>{
				if(res.success){
					this.currentType = res.data[0];
					this.typeList = res.data;
					this.monitorChange(res.data[0])
				}
			})
		},
		imageX(){
			return (this.svg.svg_width - this.imgWidth)/2;
		},
		imageY(){
			return (this.svg.svg_height - this.imgHeight)/2;
		},
		monitorChange(data){
      this.$http.get("/sensorDetail/getListByMonitorDictId",{
        params:{
          monitorDictId: data.id
        }
      }).then(res=>{
        if(res.success){
          this.sensorList = res.data
        }
      })
      if(this.svg.svg_){
        this.svg.svg_.remove();
      }
      if(this.svg.svg1){
        this.svg.svg1.remove();
      }
			this.initSvg(data.id)
			this.currentType = data.id;
			this.$emit("monitorChange", data)
		},

    // TODO
    resetFields() {
      this.dialogPoint = false;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.pointForm)
          this.$http.post('/point/addPoint',this.pointForm).then(res=>{
            if(res.success){
              this.$message.success("处理成功");
              if(this.svg.svg_){
                this.svg.svg_.remove();
              }
              if(this.svg.svg1){
                this.svg.svg1.remove();
              }
              this.initSvg(this.monitorId);
              this.dialogPoint = false;
            } else {
              this.$message.error(res.msg)
            }
          });
        }
      })
    },
  },
};
</script>
<style scope>
  .single-structure-change .change-module-one .bridge-wrapper{
    /* width: calc(100% - 230px); */
    width: 100%;
    height: 100%;
    /* margin-left: 230px; */
    position: relative;
  }
  #bridge1{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .point{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #00b4f8;
    z-index: 99;
  }
</style>
