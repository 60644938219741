<template>
<!--  <div style="display: flex" v-if="type === 'FFT'">-->
<!--    <div ref="dom" class="charts chart-bar" style="width: 50%;">-->
<!--    </div>-->
<!--    &lt;!&ndash; 查看解算详情 &ndash;&gt;-->
<!--    <div ref="fft" class="charts chart-bar" style="width: 50%;"></div>-->
<!--  </div>-->
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      text: String,
      subtext: String,
    },
    data() {
      return {
        datas1: [],
        datas2: [],
        datas3: [],
        datas4: [],
        xAxisData: [],
        series: [],
        dom: null,
        fftDom: null,
        now: new Date(),
        client: null,
        oneDay: 1000,
        yAxisName:'',
        fftDetail: {
          dialogFormVisible: false,
          xData: Array(4096).fill('').map((_, h) => (h*50/8192))
        },
        type: "",
        ip: "",
        ipData: this.$IP
      };
    },
    mounted() {
      for (var i = 0; i < 100; i++) {
        this.datas1.push(this.randomData(1));
        this.datas2.push(this.randomData(4));
        this.datas3.push(this.randomData(2));
        this.datas4.push(this.randomData(3));
      }
    },
    methods: {
      resize() {
        this.dom.resize();
        if(this.type === "FFT") this.fftDom.resize();
      },
      loadEcharts(val, data) {
        console.log("特征数据",val, data);
        this.type = val.entityData.type;
        let self = this;
        let markLine = [];
        for(let i =0;i < val.markLineList.length;i++){
          let color = ''
          if (val.markLineList[i].name.substr(0,4) === '一级阈值'){
            color = '#61A0A8'
          } else if (val.markLineList[i].name.substr(0,4) === '二级阈值'){
            color = '#CA8622'
          } else if (val.markLineList[i].name.substr(0,4) === '三级阈值'){
            color = '#C23531'
          }
          markLine.push({
            name: val.markLineList[i].name,
            yAxis: val.markLineList[i].data,
            lineStyle:{
              type:'solid',
              color:color,
              cap:'butt'
            },
          });
        }
        this.$nextTick(() => {
          self.xAxisData = val.xdata;
          self.series = val.series;
          self.yAxisName = val.unit;
          let option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: true,
              // right:120,
              top:10,
              itemStyle:{

              },
              // orient:'vertical',
              data:val.legend,
              selected:{}
            },
            grid: {
              top: 50,
              left: "6%",
              right: "6%",
              bottom: "10%",
            },
            toolbox: {
              right:20,
              top:20,
              feature: {
                dataZoom:{
                  yAxisIndex: "none"
                },
              }
            },
            xAxis: [
              {
                boundaryGap: ["2%", "2%"],
                axisLine: {
                  //坐标轴轴线相关设置。数学上的x轴
                  show: false,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  //坐标轴刻度标签的相关设置
                  color: "#5a5a5a",
                  fontSize: 14,
                  showMaxLabel: true, //强制显示最后一个数据的刻度
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                    color: "#4CC6FF",
                  },
                },
                axisTick: {
                  show: true,
                },
                data: self.xAxisData,
              },
            ],
            yAxis: [
              {
                name: self.yAxisName,
                nameTextStyle: {
                  color: "#333",
                },
                scale:true,
                boundaryGap: ["10%", "20%"],
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#eaeaea",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  show: true,
                  fontSize: 14,
                  color: "#5a5a5a",
                  padding: 5,
                  formatter: function (value) {
                    if (value === 0) {
                      return value;
                    }
                    return value;
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            series: []
          };
          let digits = val.entityData.decimal;
          let maxData = -Infinity;
          let minData = Infinity;
          if (self.series){
            let allData = self.series[0].data;
            for (let i = 0; i < allData.length; i++) {
              maxData = Math.max(maxData, allData[i]);
              minData = Math.min(minData, allData[i]);
            }
            if (markLine.length > 0) {
              markLine.forEach(item => {
                // option.legend[1].data.push(item.name)
                console.log("marklineitem",item)
                option.series.push({
                  type:'line',
                  name:item.name,
                  symbol:'none',
                  itemStyle: {
                    color:item.lineStyle.color,
                  },
                  lineStyle:{
                    color:item.lineStyle.color,
                  },
                  markLine: {
                    symbol:'none',
                    data: [item],
                    precision: digits
                  }
                })
                option.legend.selected[item.name] = false
              });
            }
            option.yAxis[0].minInterval = 1/Math.pow(10, digits);
            option.yAxis[0].axisLabel.formatter = function (value) {
              return value.toFixed(digits);
            };
            for (let i = 0; i < self.series.length;i++){
              option.series.push({
                type:'line',
                name:self.series[i].name,
                data:self.series[i].data,
              });
              option.legend.selected[self.series[i].name] = i === 0;
            }
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          if (this.type === "FFT") {
            this.dom.on('click', function (params) {
              self.fftDetail.dialogFormVisible = true;
              self.$http.get("/dehumidification/getFFT", {
                params: {
                  time: params.name
                }
              }).then(res => {
                if(res.success && res.data){
                  let data = res.data[val.series[0].columnName].split(";");
                  let fftData = data[0].split(",");
                  let coordData = data[1].split(",");
                  // 组装标记点
                  let markData = [];
                  let seriesData = [];
                  for (let i = 0; i < coordData.length; i++) {
                    let coord = coordData[i].split("-");
                    markData.push({
                      coord: [coord[0]*50/8192, coord[1]-0],
                      value: (coord[0]*50/8192).toFixed(2)
                    })
                  }
                  for (let i = 0; i < fftData.length; i++) {
                    seriesData.push([i*50/8192, fftData[i]]);
                  }
                  // console.log("markData", markData);
                  // console.log(fftData, coordData);
                  let border = self.getSigmaBorder(fftData);
                  let border1 = self.getSigmaBorder(fftData.slice(1,fftData.length));
                  let zeroData = fftData[0];
                  // console.log("上界", border);
                  self.getArrayOuter(fftData, border);

                  self.fftDom = echarts.init(self.$refs.fft);
                  self.fftDom.setOption({
                    grid: {
                      top: 50,
                      left: "6%",
                      right: "6%",
                      bottom: "10%",
                    },
                    toolbox: {
                      right:20,
                      top:20,
                      feature: {
                        dataZoom:{
                          yAxisIndex: "none"
                        },
                      }
                    },
                    xAxis: {
                      name: '',
                      type: 'value',
                      data: self.fftDetail.xData,
                      nameLocation:'center',
                      nameGap:25,
                      axisLine:{
                        onZero:false
                      },
                      splitLine:{
                        show:false
                      }
                    },
                    yAxis: {
                      name: '',
                      type: 'value',
                      nameLocation:'center',
                      nameGap:40,
                      scale: true,
                      splitLine:{
                        show:false
                      }
                    },
                    series: [
                      {
                        name: '',
                        type: "line",
                        data: seriesData,
                        symbol: "none",
                        markPoint: {
                          data: markData
                        },
                      },
                      {
                        type:'line',
                        name: "上界",
                        symbol:'none',
                        itemStyle: {
                          color: "#FF0000"
                        },
                        lineStyle:{
                          color: "#FF0000",
                        },
                        markLine: {
                          symbol:'none',
                          data: [
                            {
                              name: "border",
                              yAxis: border,
                              lineStyle:{
                                type:'solid',
                                color: "#FF0000",
                                cap:'butt'
                              },
                            }
                          ],
                          precision: digits
                        }
                      },
                      {
                        type:'line',
                        name: "上界2",
                        symbol:'none',
                        itemStyle: {
                          color: "#0000FF"
                        },
                        lineStyle:{
                          color: "#0000FF",
                        },
                        markLine: {
                          symbol:'none',
                          data: [
                            {
                              name: "border1",
                              yAxis: border1,
                              lineStyle:{
                                type:'solid',
                                color: "#0000FF",
                                cap:'butt'
                              },
                            }
                          ],
                          precision: digits
                        }
                      },
                      {
                        type:'line',
                        name: "0点值",
                        symbol:'none',
                        itemStyle: {
                          color: "#00FF00"
                        },
                        lineStyle:{
                          color: "#00FF00",
                        },
                        markLine: {
                          symbol:'none',
                          data: [
                            {
                              name: "0点值",
                              yAxis: zeroData,
                              lineStyle:{
                                type:'solid',
                                color: "#00FF00",
                                cap:'butt'
                              },
                            }
                          ],
                          precision: digits
                        }
                      }
                    ],
                  });
                }
              })
            });
          }
          this.dom.on('legendselectchanged',(obj) => {
            let allT = [];
            let maxV = null;
            let minV = Infinity;
            for (const nameKey in obj.selected) {
              if ((nameKey.includes("阈值上限") || nameKey.includes("阈值下限")) && obj.selected[nameKey]) allT.push(nameKey);
            }
            let option = self.dom.getOption();
            let series = option.series;
            for (let i = 0; i < allT.length; i++) {
              let index = 0;
              for (let j = 0; j < series.length; j++) {
                if (series[j].name === allT[i]) {
                  index = j;
                  break;
                }
              }
              let markValue = series[index].markLine.data[0].yAxis;
              maxV = Math.max(maxV, markValue);
              minV = Math.min(minV, markValue);
            }
            maxV = Math.max(maxV, maxData, minData);
            minV = Math.min(minV, maxData, minData);
            let resMax = (allT.length === 0) ? null : maxV;
            let resMin = ((allT.length === 0) || minV === Infinity) ? null : minV;
            let scale = 0.01;
            option.yAxis[0].max = resMax === null ? null : ((resMax > 0 ? resMax*(1+scale) : resMax*(1-scale)).toFixed(digits));
            option.yAxis[0].min = resMin === null ? null : ((resMin > 0 ? resMin*(1-scale) : resMin*(1+scale)).toFixed(digits));
            option.yAxis[0].minInterval = 1/Math.pow(10, digits);
            option.yAxis[0].axisLabel.formatter = function (value) {
              return value.toFixed(digits);
            };
            self.dom.setOption(option);
          });
          on(window, "resize", this.resize);
        });
      },
      randomData(data) {
        let self = this;
        self.now = new Date(+self.now + self.oneDay);
        return {
          name: self.now.toString(),
          value: [self.now, Math.random() * data],
        };
      },
      getMqData(body){
        for (let item of body){
          this.xAxisData.push(item.datetime);
        }
        if (this.xAxisData){
          if(this.xAxisData.length >= 1000){
            let sum = this.xAxisData.length - 1000
            this.xAxisData.splice(0,sum);
          }
        } else {
          this.xAxisData = []
        }
        // for (let item of body){
        //   this.xAxisData.push(item.datetime);
        // }
        if (this.series){
          for(let i = 0; i < this.series.length; i++){
            if(this.series[i].data.length >= 1000){
              let sum = this.series[i].data.length - 1000
              this.series[i].data.splice(0,sum);
            }
            for (let item of body){
              this.series[i].data.push(item[this.series[i].columnName])
            }
          }
        } else {
          this.series = []
        }
        if (this.series.length > 0 || this.xAxisData.length > 0){
          this.dom.setOption({
            series: this.series,
            xAxis: [{
              data: this.xAxisData
            }]
          });
        }
      },
      // 获取上界之外的数据
      getArrayOuter(data, border) {
        let array = [];
        for (let i = 0; i < data.length; i++) {
          let item = data[i] - 0;
          if(item>border) array.push(item);
        }
        console.log(array.length, array);
      },
      // 求上界
      getSigmaBorder(data) {
        let avg = this.getAvg(data);
        let sigma = this.getSigma(data,avg);
        return (avg + 3 * sigma).toFixed(4);
      },
      // 求数组标准差
      getSigma(data,avg) {
        let sigma = 0;
        for (let i = 0; i < data.length; i++) {
          let item = data[i] - 0;
          sigma += Math.pow(item-avg,2);
        }
        return Math.sqrt(sigma/data.length);
      },
      // 求平均值
      getAvg(data) {
        let val = 0;
        for (let i = 0; i < data.length; i++) {
          let item = data[i] - 0;
          val += item;
        }
        return val/data.length;
      }
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
