<template>
  <div class="module-title flex justify-between items-center">
    <div class="flex items-center">
      <img
        src="../../../../../../assets/images/admin/single/icon/icon_tongji.png"
        alt=""
      />
      <span class="title-text ml-3">{{ sensorName }}{{ titleText }}</span>
    </div>
    <el-form :model="form" class="search-form" :inline="true" size="medium">
      <el-form-item v-if="typeShow">
        <el-select
          v-model="form.type"
          prefix-icon="el-icon-search"
          @change="change"
        >
<!--          <el-option label="原始值" :value="0"></el-option>-->
          <el-option label="相对值" :value="1"></el-option>
          <el-option label="绝对值" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="directionShow">
        <el-select
          v-model="form.direction"
          prefix-icon="el-icon-search"
          @change="change"
        >
          <el-option label="左幅" :value="12"></el-option>
          <el-option label="右幅" :value="14"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="bridgeShow">
        <el-select
          v-model="form.bridge"
          prefix-icon="el-icon-search"
          @change="change"
        >
          <el-option label="横桥向" :value="8"></el-option>
          <el-option label="顺桥向" :value="10" v-if="!isSk"></el-option>
          <el-option label="竖向" :value="10" v-if="isSk"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="sensorShow">
        <el-select
          v-model="form.sensorIdList"
          v-if="sensorMul"
          key="多选"
          collapse-tags
          multiple
          prefix-icon="el-icon-search"
          @change="change"
        >
          <el-option
            v-for="item in sensorDetaiList"
            :key="item.id"
            :label="item.subName"
            :value="item.id"
          >
          </el-option
        ></el-select>
        <el-select
          v-model="form.sensorId"
          v-else
          key="单选"
          prefix-icon="el-icon-search"
          @change="change1"
        >
          <el-option
            v-for="item in sensorDetaiList"
            :key="item.id"
            :label="item.subName"
            :value="item.id"
          >
          </el-option
        ></el-select>
      </el-form-item>
      <el-form-item v-if="gnssShow">
        <el-select
            v-model="form.gnssId"
            prefix-icon="el-icon-search"
            @change="change"
        >
          <el-option
              v-for="item in gnssTypeList"
              :key="item.id"
              :label="item.simpleName"
              :value="item.id"
          >
          </el-option
          ></el-select>
      </el-form-item>
      <el-form-item v-if="rangeShow">
        <el-date-picker
          v-if="dateType"
          v-model="form.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeTime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
        <el-date-picker
          v-else
          v-model="form.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="change"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="timeShow">
        <el-date-picker
          v-model="form.dateTime"
          type="datetime"
          placeholder="请选择时间"
          @change="change"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "chartTitle",
  props: {
    sensorDetaiList: Array,
    titleText: String,
    dateType: String,
    sensorType: String,
    sensorMul: Boolean,
    typeShow: Boolean,
    bridgeShow: Boolean,
    directionShow: Boolean,
    sensorShow: Boolean,
    rangeShow: Boolean,
    timeShow: Boolean,
    gnssShow:Boolean,
    timeArr:Array,
    gnssTypeList:Array,
    currentSensorList:[]
  },
  data() {
    return {
      form: {
        time: [],
        type: 1,
        bridge: 8,
        direction: 12,
        dateTime: '',
      },
      sensorName: "",
      isSk: localStorage.getItem("baseUrl") === "djksk"
    };
  },
  created() {
    this.initTime();
  },
  watch: {
    sensorDetaiList: {
      handler(val) {
        this.initTime();
        if (val && val.length) {
          this.sensorDetaiList = val;
          this.initSensor(val);
        }
      },
      deep: true,
      immediate: true,
    },
    gnssTypeList: {
      handler(val) {
        this.initTime();
        if (val && val.length) {
          this.gnssTypeList = val;
          this.initGnssType(val);
          this.$emit("changeForm", this.form);
        }
      },
      deep: true,
      immediate: true,
    },
    dateType: {
      handler(val) {
        this.initTime();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initTime() {
      let date = new Date();
      this.form.dateTime = this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
      if (this.dateType) {
        this.form.time[0] = this.$moment(date).subtract(3, "minutes").format("YYYY-MM-DD HH:mm:ss");
        this.form.time[1] = this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
      } else if (this.sensorType && (this.sensorType.indexOf('A1') !== -1 || this.sensorType.indexOf('B1') !== -1)) {
        this.form.time[0] = this.$moment(date).subtract(9, "days").format("YYYY-MM-DD");
        this.form.time[1] = this.$moment(date).format("YYYY-MM-DD");
      } else if (this.sensorType && (this.sensorType.indexOf('C1') !== -1)) {
        this.form.time[0] = this.$moment(date).subtract(4, "days").format("YYYY-MM-DD");
        this.form.time[1] = this.$moment(date).format("YYYY-MM-DD");
      } else {
        this.form.time[0] = this.$moment(date).subtract(3, "days").format("YYYY-MM-DD");
        this.form.time[1] = this.$moment(date).format("YYYY-MM-DD");
      }
      if (this.timeArr && this.timeArr.length > 0){
        this.form.time[0] = this.timeArr[0]
        this.form.time[1] = this.timeArr[1]
      }
      console.log(this.form.time)
    },
    initSensor(list) {
      if (this.sensorShow) {
        if (this.sensorMul) {
          this.sensorName = "";
          if (list.length > 0) {
            this.form.sensorIdList = [list[0].id];
            if (this.currentSensorList && this.currentSensorList.length > 0){
              this.form.sensorIdList = [...new Set(this.currentSensorList)]
            }
            // 针对丹江口水库桥的结构温度特殊处理
            if (list[0].sensorKey.includes("FOS")) {
              this.form.sensorIdList = [].map.call(list.slice(18,29), (item)=>item.id);
            }
          }
        } else {
          if (list.length > 0) {
            this.sensorName = list[0].subName;
            this.form.sensorId = list[0].id;
            // 针对丹江口水库桥的结构温度特殊处理
            if (list[0].sensorKey.includes("FOT")) {
              this.sensorName = list[41].subName;
              this.form.sensorId = list[41].id;
            }
          }
        }
      } else {
        this.sensorName = "";
      }
      this.$emit("changeForm", this.form);
    },
    initGnssType(list){
      if (list.length > 0) {
        this.form.gnssId = list[0].id;
      }
    },
    change() {
      this.$forceUpdate();
      this.$emit("changeForm", this.form);
    },
    changeTime(e) {
      if (this.$moment(e[1]).unix() - this.$moment(e[0]).unix() > 3600) {
        this.$alert("时间范围不能超过1小时", "提示", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        return;
      }
      this.$forceUpdate();
      this.$emit("changeForm", this.form);
    },
    change1(e) {
      if (this.sensorDetaiList.length > 0) {
        for (let item of this.sensorDetaiList) {
          if (item.id == e) {
            this.sensorName = item.subName;
          }
        }
      }
      this.$forceUpdate();
      this.$emit("changeForm", this.form);
    },
  },
};
</script>

<style scoped>
.sta-title {
  height: 60px;
  padding: 0 20px;
  background: #fff;
  margin-bottom: 10px;
}
.title-text {
  font-size: 16px;
  color: #1a67d0;
  font-weight: 900;
}
/deep/ .el-select .el-tag {
  max-width: 66%;
}
</style>
