<template>
  <div class="common-module change-module-five relative">
    <el-form :model="form" class="search-form" :inline="true" size="medium">
      <el-form-item>
        <el-select v-model="form.info" prefix-icon="el-icon-search" @change="change">
          <el-option
              v-for="item in sensorDetailList"
              :key="item.id"
              :label="item.subName"
              :value="item.id"
          >
          </el-option
          ></el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
            v-model="form.time"
            type="datetimerange"
            :clearable="false"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="change"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="module-content" style="margin-top: 40px;">
      <c-table
        :data="pageData"
        :tableColumns="tableColumns"
        v-loading="pageLoading"
        :stripe="true"
        :pagination="pageParams"
        @change="pageGet"
      >
        <template v-slot:handleStatus="{ row }">
          <span>{{row.handleStatus === 0 ? '未处理' : (row.handleStatus === 1 ? '已处理' : (row.handleStatus === 2 ? '已忽略' : ''))}}</span>
        </template>
        <template v-slot:custom="{ row }">
          <span class="opt-detail" @click="getDetail(row)">详情</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        title="详情"
        :visible.sync="visible"
        :destroy-on-close="true"
        width="1100px"
    >
      <div class="health-chart">
        <chart-one ref="chartOne" v-loading="loading" style="height: 400px" :value="chartData"></chart-one>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MPage from "@/mixins/MPage.js";
import Configs from "../config/moduleFive";
import chartOne from "@/page/single/manage/alarm/process/chart/chart01";
let mPage = MPage({
  pagePath: "",
  pageDelPath: "",
  pageDelsPath: "",
  pageParams: {},
});
export default {
  mixins: [mPage],
  components:{chartOne},
  data() {
    let { tableColumns } = Configs(this);
    return {
      tableColumns,
      form: {
        info: "",
        time: [],
      },
      pageLoading: false,
      pageData: [],
      sensorDetailList: [],
      visible:false,
      loading:false,
      chartData: {
        legend:[],
        markLineList:[]
      },
    };
  },
  created() {
    let date = new Date()
    this.form.time[0] = this.$moment(date).subtract(1, "day").format('YYYY-MM-DD HH:mm:ss');
    this.form.time[1] = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
    this.pageParams.startTime = this.form.time[0]
    this.pageParams.endTime = this.form.time[1]
  },
  methods: {
    getSensorDetail(data) {
      this.sensorDetailList = data;
      if (this.sensorDetailList.length > 0){
        this.loading = true
        this.form.info = this.sensorDetailList[0].id
        this.pageParams.sensorDetailId = this.form.info;
        this.pagePath = "/sensor/warn/listPage";
        this.pageGet(true);
      }
    },
    getDetail(data){
      let self = this
      self.loading = true
      self.$http.post('/sensor/warn/detail',{pointId:data.sensorDetailId,dateTime:data.datetime}).then(res => {
        if (res.success){
          if (res.data){
            res.data.colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
            self.chartData = res.data
          }
          self.visible = true
        } else {
          this.$message.error(res.mag)
        }
        this.loading = false
      })
    },
    change() {
      if (!this.form.info){
        this.$message.warning('请选择传感器')
        return
      }
      if (!this.form.time || this.form.time.length === 0){
        this.$message.warning('请选择时间')
        return
      }
      this.pageParams.sensorDetailId = this.form.info
      this.pageParams.startTime = this.form.time[0]
      this.pageParams.endTime = this.form.time[1]
      this.pageGet(true);
    },
  },
};
</script>
