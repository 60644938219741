export default function Configs() {

    const tableColumns = [
        {
            label: "传感器编号",
            prop: "sensorDetailName",
            align: "center",
            show: true
        },
        {
            label: "传感器名称",
            prop: "sensorName",
            align: "center",
            show: true
        },
        {
            label: "传感器位置",
            prop: "subName",
            align: "center",
            show: true
        },
        {
            label: "警报等级",
            prop: "warnLevel",
            align: "center",
            show: true
        },
        {
            label: "警报时间",
            prop: "datetime",
            align: "center",
            show: true,
            width:160
        },
        {
            label: "处理状态",
            prop: "handleStatus",
            name:"handleStatus",
            align: "center",
            show: true
        },
        {
            label: "操作",
            name: 'custom',
            align: "center",
            show: true
        },
    ];
    return {
        tableColumns,
    };
}
