export default function Configs() {

  const tableColumns = [
    {
      label: "监测点名称",
      prop: "subName",
      align: "center",
      show: true
    },
    // {
    //   label: "报警状态",
    //   name: 'status',
    //   // prop: "name",
    //   align: "center",
    //   show: true
    // },
    {
      label: "当前值",
      prop: "sensorData",
      align: "center",
      show: true
    },
    // {
    //   label: "单位",
    //   prop: "unit",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "蓝色报警上限",
    //   prop: "firstWarnUpper",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "蓝色报警下限",
    //   prop: "firstWarnFloor",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "黄色报警上限",
    //   prop: "secondWarnUpper",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "黄色报警下限",
    //   prop: "secondWarnFloor",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "红色报警上限",
    //   prop: "thirdWarnUpper",
    //   align: "center",
    //   show: true
    // },
    // {
    //   label: "红色报警下限",
    //   prop: "thirdWarnFloor",
    //   align: "center",
    //   show: true
    // },
  ];
  return {
    tableColumns,
  };
}
