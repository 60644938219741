<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
  import * as echarts from "echarts";
  import { on, off } from "@/libs/tools";
  export default {
    name: "ChartBar",
    props: {
      value: Object,
      text: String,
      subtext: String,
    },
    data() {
      return {
        xAxisData: [],
        series: [],
        dom: null,
        now: new Date(),
        yAxisName:''
      };
    },
    watch: {
      value: {
        handler(val) {
          this.loadEcharts(val);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      // this.loadEcharts();
    },
    methods: {
      resize() {
        this.dom.resize();
      },
      loadEcharts(val) {
        let self = this;
        let markLine = []
        if (val.markLineList.length > 0){
          for(let i =0;i < val.markLineList.length;i++){
            let color = ''
            if (val.markLineList[i].name.substr(0,4) === '一级阈值'){
              color = '#61A0A8'
            } else if (val.markLineList[i].name.substr(0,4) === '二级阈值'){
              color = '#CA8622'
            } else if (val.markLineList[i].name.substr(0,4) === '三级阈值'){
              color = '#C23531'
            }
            markLine.push({
              name: val.markLineList[i].name,
              yAxis: val.markLineList[i].data,
              lineStyle:{
                type:'solid',
                color:color,
                cap:'butt'
              },
            });
          }
        }
        this.$nextTick(() => {
          self.xAxisData = val.xaxis;
          self.series = [val.data];
          // self.yAxisName = val.unit
          let option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: true,
              // right:120,
              top:10,
              itemStyle:{

              },
              // orient:'vertical',
              data:val.orderList,
              selected:{}
            },
            grid: {
              top: 50,
              left: 80,
              right: 50,
              bottom: "10%",
            },
            xAxis: [
              {
                boundaryGap: ["2%", "2%"],
                axisLine: {
                  //坐标轴轴线相关设置。数学上的x轴
                  show: false,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  //坐标轴刻度标签的相关设置
                  color: "#5a5a5a",
                  fontSize: 14,
                  showMaxLabel: true, //强制显示最后一个数据的刻度
                },
                splitLine: {
                  show: false,
                  lineStyle: {
                    type: "dashed",
                    color: "#4CC6FF",
                  },
                },
                axisTick: {
                  show: true,
                },
                data: self.xAxisData,
              },
            ],
            yAxis: [
              {
                name: '',
                nameTextStyle: {
                  color: "#333",
                },
                // min: val.ymin,
                // max: val.ymax,
                scale:true,
                boundaryGap: ["10%", "20%"],
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: "#eaeaea",
                  },
                },
                axisLine: {
                  show: true,
                  lineStyle: {
                    color: "#C3C3C3",
                  },
                },
                axisLabel: {
                  show: true,
                  fontSize: 14,
                  color: "#5a5a5a",
                  padding: 5,
                  formatter: function (value) {
                    if (value === 0) {
                      return value;
                    }
                    return value;
                  },
                },
                axisTick: {
                  show: false,
                },
              },
            ],
            series: []
          };
          let digits = val.entityData.decimal;
          let maxData = -Infinity;
          let minData = Infinity;
          if (self.series){
            let allData = self.series[0].yaxis;
            for (let i = 0; i < allData.length; i++) {
              maxData = Math.max(maxData, allData[i]);
              minData = Math.min(minData, allData[i]);
            }
            option.yAxis[0].minInterval = 1/Math.pow(10, digits);
            option.yAxis[0].axisLabel.formatter = function (value) {
              return value.toFixed(digits);
            };
            if (markLine.length > 0){
              markLine.forEach(item => {
                option.series.push({
                  type:'line',
                  name:item.name,
                  symbol:'none',
                  itemStyle: {
                    color:item.lineStyle.color,
                  },
                  lineStyle:{
                    color:item.lineStyle.color,
                  },
                  markLine: {
                    symbol:'none',
                    data: [item],
                    precision: digits
                  }
                })
                option.legend.selected[item.name] = false
              });
            }
            for (let i = 0; i < self.series.length;i++){
              option.series.push({
                type:'line',
                name:self.series[i].type,
                data:self.series[i].yaxis,
                symbol:'none'
              });
              // option.legend.data.push(self.series[i].name)
              option.legend.selected[self.series[i].type] = i === 0;
            }
          }
          if (this.dom != null && this.dom != "" && this.dom != undefined) {
            this.dom.dispose();
          }
          this.dom = echarts.init(this.$refs.dom);
          this.dom.setOption(option);
          on(window, "resize", this.resize);
          // let that = this
          // that.dom.on('legendselectchanged',function (obj) {
          //   console.log(obj);
          //   option.yAxis[0].min = 0
          //   option.legend.selected = obj.selected
          //   that.dom.setOption(option);
          // })
          this.dom.on('legendselectchanged',(obj) => {
            let allT = [];
            let maxV = null;
            let minV = Infinity;
            for (const nameKey in obj.selected) {
              if ((nameKey.includes("阈值上限") || nameKey.includes("阈值下限")) && obj.selected[nameKey]) allT.push(nameKey);
            }
            let option = self.dom.getOption();
            let series = option.series;
            for (let i = 0; i < allT.length; i++) {
              let index = 0;
              for (let j = 0; j < series.length; j++) {
                if (series[j].name === allT[i]) {
                  index = j;
                  break;
                }
              }
              let markValue = series[index].markLine.data[0].yAxis;
              maxV = Math.max(maxV, markValue);
              minV = Math.min(minV, markValue);
            }
            maxV = Math.max(maxV, maxData, minData);
            minV = Math.min(minV, maxData, minData);
            let resMax = (allT.length === 0) ? null : maxV;
            let resMin = ((allT.length === 0) || minV === Infinity) ? null : minV;
            let scale = 0.01;
            option.yAxis[0].max = resMax === null ? null : ((resMax > 0 ? resMax*(1+scale) : resMax*(1-scale)).toFixed(digits));
            option.yAxis[0].min = resMin === null ? null : ((resMin > 0 ? resMin*(1-scale) : resMin*(1+scale)).toFixed(digits));
            option.yAxis[0].minInterval = 1/Math.pow(10, digits);
            option.yAxis[0].axisLabel.formatter = function (value) {
              return value.toFixed(digits);
            };
            self.dom.setOption(option);
          });
        });
      },
      randomData(data) {
        let self = this;
        self.now = new Date(+self.now + self.oneDay);
        return {
          name: self.now.toString(),
          value: [self.now, Math.random() * data],
        };
      },
      getMqData(body){
        for (let item of body){
          this.xAxisData.push(item.datetime);
        }
        if (this.xAxisData){
          if(this.xAxisData.length >= 1000){
            let sum = this.xAxisData.length - 1000
            this.xAxisData.splice(0,sum);
          }
        } else {
          this.xAxisData = []
        }
        if (this.series){
          for(let i = 0; i < this.series.length; i++){
            if(this.series[i].data.length >= 1000){
              let sum = this.series[i].data.length - 1000
              this.series[i].data.splice(0,sum);
            }
            for (let item of body){
              this.series[i].data.push(item[this.series[i].columnName])
            }
          }
        } else {
          this.series = []
        }
        if (this.series.length > 0 || this.xAxisData.length > 0){
          this.dom.setOption({
            series: this.series,
            xAxis: [{
              data: this.xAxisData
            }]
          });
        }
      },
    },

    beforeDestroy() {
      off(window, "resize", this.resize);
    },
  };
</script>
