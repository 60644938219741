
<template>
  <div class="common-module change-module-four relative">
    <div class="module-content">
      <div class="search-form" v-if="showRadio">
        <el-radio-group v-model="type" size="small" @change="changeType">
          <el-radio-button :label="0">绝对值</el-radio-button>
          <el-radio-button :label="1">相对值</el-radio-button>
        </el-radio-group>
      </div>
      <div class="module-left">
        <chart-one ref="chartOne" v-loading="loading" style="height: 100%" :value="chartData"></chart-one>
      </div>
      <div class="threshold">
        <div class="th-item" v-for="(item,i) in sensorData" :key="i" @click="showChart2(sensorData,item,i)">
          <div class="th-icon" v-if="form.sensorData.indexOf(item.id) !== -1" :style="'background:' + colorList[form.sensorData.indexOf(item.id) % 9]">
            <div class="th-icon-circle" :style="'background:' + colorList[form.sensorData.indexOf(item.id) % 9]"></div>
          </div>
          <div class="th-icon" v-else style="background:#999">
            <div class="th-icon-circle" style="background:#999"></div>
          </div>
          <div class="th-name" :title="item.subName">{{item.subName}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chartOne from "../charts/chart01.vue";
export default {
  props:{
    dataType: Number,
  },
  components: {
    chartOne,
  },
  data() {
    return {
      colorList:[
          '#5470c6',
          '#91cc75',
          '#fac858',
          '#ee6666',
          '#73c0de',
          '#3ba272',
          '#fc8452',
          '#9a60b4',
          '#ea7ccc'],
      chartData: [],
      form: {
        sensorData: [],
      },
      list:[],
      chart:{
        series:[],
        unit:'',
        xdata:[]
      },
      allList:[],
      testList:[],
      loading:false,
      sensorData:[],
      sensorData1:[],
      topic:null,
      selectList:[],
      name:'',
      showRadio:false,
      type: this.dataType
    };
  },
	methods: {
    changeType(){
      this.currentLineData(this.sensorData,this.name,'select');
      this.$emit("typeChange", this.type);
    },
    //桥模型上圆点点击触发
    changeByPoint(point){
      for(let item of this.sensorData){
        if (item.id == point.sensorDetailId){
          this.form.sensorData = [item.id]
          this.getData()
          return
        }
      }
    },
    currentLineData(data,name,type){
      return new Promise(resolve=>{
        this.name = name
        this.loading = true;
        if (data.length > 0){
          if (!type){
            this.sensorData = data
            if (this.sensorData[0].calValue || this.sensorData[0].calValue === 0) {
              this.showRadio = true;
            } else {
              this.showRadio = false;
            }
            this.form.sensorData = [this.sensorData[0].id];
          }
          if (this.name === '结构振动'){
            this.showRadio = false
          }
          this.$http.post("/common/currentLineData",{
            sensorIdList:this.form.sensorData
          }).then(res=>{
            if(res.success){
              if (this.type == 1){
                let seriesList = res.data.chart.series
                for(let item of seriesList){
                  let obj = this.sensorData.filter(sensor => {return sensor.columnName === item.columnName})
                  if (obj[0].isOpposite == 1){
                    item.data = item.data.map(function(element){
                      return (Number(obj[0].calValue) - element).toFixed(4);
                    });
                  } else {
                    item.data = item.data.map(function(element){
                      return (element - Number(obj[0].calValue)).toFixed(4);
                    });
                  }
                }
                res.data.chart.series = seriesList
              }
              this.initChart(res.data,data,name);
              this.topic = res.data.topic;
            } else {
              this.changeLoad()
              this.$message.error(res.msg)
              this.initChart({chart:{}});
            }
            return resolve();
          })
        } else {
          this.changeLoad()
          return resolve();
        }
      }).catch(err=>{
        this.changeLoad()
        return resolve();
      })
    },
    showChart2(list,data){
      let n = this.form.sensorData.indexOf(data.id)
      if (n === -1){
        this.form.sensorData.push(data.id)
        //将id从小到大排序，避免用户随机点击图例时，图例的颜色和线的颜色不对应
        this.form.sensorData.sort()
        this.getData()
      } else {
        if (this.form.sensorData.length === 1){
          this.form.sensorData.splice(n,1)
          this.initChart({chart:{}});
          return
          // this.$alert('至少选择一个测点', '提示', {
          //   confirmButtonText: '确定',
          //   callback: () => {}
          // });
        } else {
          this.form.sensorData.splice(n,1)
          this.getData()
        }
      }
    },
    getData(){
      this.loading = true
      this.$http.post("/common/currentLineData",{
        sensorIdList:this.form.sensorData
      }).then(res=>{
        if(res.success){
          if (this.type == 1){
            let seriesList = res.data.chart.series
            for(let item of seriesList){
              let obj = this.sensorData.filter(sensor => {return sensor.columnName === item.columnName})
              if (obj[0].isOpposite == 1){
                item.data = item.data.map(function(element){
                  return (Number(obj[0].calValue) - element).toFixed(4);
                });
              } else {
                item.data = item.data.map(function(element){
                  return (element - Number(obj[0].calValue)).toFixed(4);
                });
              }
            }
            res.data.chart.series = seriesList
          }
          this.initChart(res.data, this.sensorData);
          this.topic = res.data.topic;
        } else {
          this.changeLoad()
          this.$message.error(res.msg)
          this.initChart({chart:{}});
        }
        this.loading = false
      })
    },
		initChart(params,data,name) {
      this.$refs["chartOne"].loadEcharts(params.chart,data,name);
		},
		getMqData(body){
      this.$refs["chartOne"].getMqData(body,this.name,this.sensorData,this.type);
		},
    changeLoad(){
      this.loading = false
    }
	},

};
</script>
<style scoped>
  .single-structure-change .change-module-four{
    height: 440px;
  }
  .common-module .module-content{
    height: 100%;
  }
  .threshold{
    position: absolute;
    right: 0;
    top: 54px;
    width: 116px;
    height: 300px;
    overflow-y: auto;
  }
  .threshold::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }
  .threshold::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .threshold::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #999;
  }
  .th-item{
    display: flex;
    align-items: center;
    height: 25px;
    line-height: 25px;
  }
  .th-item:hover{
    cursor: pointer;
  }
  .th-icon{
    width: 20px;
    height: 2px;
    margin-right: 10px;
    position: relative;
  }
  .th-icon-circle{
    position: absolute;
    left: 50%;
    top: -4px;
    margin-left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .th-name{
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .search-form{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-end;
  }
  .module-left{
    width: 100%;
    height: calc(100% - 85px);
    position: absolute;
    top: 40px;
  }

</style>
